import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Item = {
    name: string;
    value: any;
}

type DropdownProps = {
    label: string;
    items: Item[];
    selectedValue: any;
    onChange: (value: any) => void;
}

const useStyles = makeStyles(() => ({
    container: {
        '& .MuiOutlinedInput-root': {
            height: 50,
            width: 200,

            '& fieldset': {
                borderColor: 'initial',
            },

            '&:hover fieldset': {
                borderColor: 'gray',
            },

            '&.Mui-focused fieldset': {
                borderColor: '#fa4903',
            },
        },

        '& .MuiInputLabel-outlined': {
            color: 'initial',
            '&.Mui-focused': {
                color: '#fa4903',
            },
        },
    },

    listItem: {
        '&.Mui-selected': {
            backgroundColor: '#f8d6c8 !important'
        },
    }
}))

export function Dropdown(props: DropdownProps) {
    const classes = useStyles();
    const { label, items, selectedValue, onChange } = props;

    const handleChange = (e: SelectChangeEvent) => {
        onChange(e.target.value);
    }

    return (
        <FormControl
            variant="outlined"
            className={classes.container}
        >
            <InputLabel>{label}</InputLabel>
            <Select
                label={label}
                value={selectedValue}
                onChange={handleChange}
            >
                {items.map((item, index) => (
                    <MenuItem
                        key={index}
                        value={item.value}
                        classes={{ selected: classes.listItem }}
                    >
                        {item.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}