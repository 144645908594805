import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import EmptyPage from "./EmptyPage";

export type Column = {
    id: string;
    label: string;
}

type PaginatedTableProps = {
    rows: any[];
    columns: Column[];
    noResultsMessage?: string;
    page: number;
    numRows: number;
    getRowValue?: (row: any, column: Column) => any;
    getRowStyle?: (row: any) => any;
    totalRows: number;
    onPageChange: (page: number) => void;
}

const useStyles = makeStyles({
    container: {
        width: '80%',
        overflow: 'hidden',
        borderRadius: 3
    },

    header: {
        boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px'
    },

    columnCell: {
        minWidth: 100,
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 15
    },

    rowCell: {
        textAlign: 'center',
        fontSize: 15,
    }
})

export function PaginatedTable(props: PaginatedTableProps) {
    const classes = useStyles();
    const {
        rows,
        columns,
        noResultsMessage = 'No results found',
        page,
        numRows,
        getRowValue,
        getRowStyle,
        totalRows,
        onPageChange
    } = props;

    if (rows.length === 0) {
        return (
            <Paper
                className={classes.container}
                elevation={3}
            >
                <EmptyPage
                    message={noResultsMessage}
                    style={{ height: '70vh' }}
                />
            </Paper>
        )
    }

    return (
        <Paper
            className={classes.container}
            elevation={3}
        >
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead className={classes.header}>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    className={classes.columnCell}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.slice(page * numRows, page * numRows + numRows)
                            .map((row, idx) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={`${row.id}-${idx}`}
                                    >
                                        {columns.map((column) => {
                                            const value = getRowValue ? getRowValue(row, column) : row[column.id];
                                            const style = getRowStyle ? getRowStyle(row) : {};

                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    className={classes.rowCell}
                                                    sx={style}
                                                >
                                                    {value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                rowsPerPageOptions={[numRows]}
                count={totalRows}
                rowsPerPage={numRows}
                page={page}
                onPageChange={(_, newPage) => onPageChange(newPage)}
            />
        </Paper>
    )
}