// @ts-expect-error
import { Spinner } from "loading-animations-react";
import styles from "../styles/Loading.module.css";

type LoadingProps = {
    message?: string;
}

export default function Loading(props: LoadingProps) {
    const { message } = props;

    return (
        <div id={styles.container}>
            <Spinner
                color1='#fa5f22'
                color2='#ff7f4d'
                text=''
                id={styles.loading}
            />

            {message && (
                <h1 id={styles.message}>
                    {message}
                </h1>
            )}
        </div>
    )
}