import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import { auth } from "../../firebaseConfig";
import { errorAlert, fetchRequest } from "../../utils/GlobalUtils";
import Progress from "./Progress";
import Banking from "./Steps/Banking";
import Complete from "./Steps/Complete";
import OrderTags from "./Steps/OrderTags";
import Subscription from "./Steps/Subscription";

type StepType = {
    title: string
    component: React.ReactNode
}

export default function Onboarding() {
    const [loading, setLoading] = useState(true)
    const [activeStep, setActiveStep] = useState('subscription')

    const steps = ['Subscription', 'PVR Tags', 'Banking', 'Tutorial']
    const stepLookup: { [key: string]: StepType } = {
        'subscription': {
            title: 'Subscription',
            component: <Subscription />
        },
        'pvr_tags': {
            title: 'PVR Tags',
            component: <OrderTags />
        },
        'banking': {
            title: 'Banking',
            component: <Banking status="start" />
        },
        'banking-pending': {
            title: 'Banking',
            component: <Banking status="pending" />
        },
        'banking-failed': {
            title: 'Banking',
            component: <Banking status="failed" />
        },
        'complete': {
            title: 'Tutorial',
            component: <Complete />
        },
    }

    useEffect(() => {
        const step = new URLSearchParams(window.location.search).get('step')
        if (step) {
            setActiveStep(step)
            setLoading(false)
            return
        }

        const getOnboardingStatus = async () => {
            const res = await fetchRequest('getOnboardingStatus', {
                uid: auth.currentUser?.uid
            })

            if (res.status) {
                setActiveStep(res.status)
            }
        }

        getOnboardingStatus()
            .catch(errorAlert)
            .finally(() => setLoading(false))
    }, [])

    if (loading) {
        return <Loading />
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                height: '100vh',
                width: '100vw',
            }}
        >
            <Progress
                title="Onboarding"
                steps={steps}
                activeStep={steps.indexOf(stepLookup[activeStep].title)}
            />

            {stepLookup[activeStep].component}
        </Box>
    )
}