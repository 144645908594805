import { makeStyles } from "@mui/styles"
import { Box } from "@mui/system"
import { IoRocketOutline } from "react-icons/io5"

type EmptyPageProps = {
    message: string,
    style?: Record<string, string>
}

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        paddingTop: '25vh',
        boxSizing: 'border-box',

        '& h1': {
            margin: 0,
            fontSize: 20,
            color: 'gray',
            marginTop: 30,
            fontWeight: '600'
        },

        '& .messageWrapper': {
            width: '40vw',
            textAlign: 'center'
        }
    }
}))

export default function EmptyPage(props: EmptyPageProps) {
    const classes = useStyles()
    const { message, style = {} } = props

    return (
        <Box className={classes.root} style={{ ...style }}>
            <IoRocketOutline size={90} color="#fa4903" />
            <Box className='messageWrapper'>
                <h1>{message}</h1>
            </Box>
        </Box>
    )
}