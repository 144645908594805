import OnboardingContent from "../../components/OnboardingSteps/OnboardingContent";

export default function AgreementConsent() {
    const notStartedParagraphs = [
        'In order for PVR to facilitate a legally binding contract between you and your customers, we must obtain your consent to do so. Please use the Rental Agreement screen within the app to initiate this process.',
        'To access the Rental Agreement screen, navigate to the drawer menu by pressing the button in the top left corner and select "Rental Agreement".',
        'Once you have completed the consent process, refresh this page to continue.'
    ]

    return (
        <OnboardingContent
            title='Consent Required'
            paragraphs={notStartedParagraphs}
        />
    )
}