import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import LiveInventory from '../../pages/Live Inventory/LiveInventory';
import { AppComplete } from '../../pages/Misc/AppComplete';
import Onboarding from '../../pages/Onboarding/Onboarding';
import { RentalAgreement } from '../../pages/Rental Agreement/RentalAgreement';
import FindLiveInventory from '../../pages/User Manual/FindLiveInventory';
import UserManualPreview from '../../pages/User Manual/Preview';
import UserManual from '../../pages/User Manual/UserManual';
import AuthGuard from './AuthGuard';
import MobileGuard from './MobileGuard';
import UserInfoGuard from './UserInfoGuard';

function AppRouter() {
    return (
        <Router>
            <Routes>
                <Route path="/user-manual" Component={UserManual} />
                <Route path="/user-manual/preview" Component={UserManualPreview} />
                <Route
                    path="/find-live-inventory"
                    Component={() => (
                        <AuthGuard>
                            <FindLiveInventory />
                        </AuthGuard>
                    )}
                />
                <Route
                    path="/onboarding"
                    Component={() => (
                        <UserInfoGuard checkEULA={true}>
                            <Onboarding />
                        </UserInfoGuard>
                    )}
                />
                <Route
                    path="/live-inventory"
                    Component={LiveInventory}
                />
                <Route
                    path="/app-complete"
                    Component={AppComplete}
                />
                <Route
                    path="/verifying"
                    Component={() => (
                        <AppComplete
                            message='Verifying...'
                            loading={true}
                        />
                    )}
                />
                <Route
                    path="/rental-agreement"
                    Component={() => (
                        <MobileGuard>
                            <AuthGuard>
                                <RentalAgreement />
                            </AuthGuard>
                        </MobileGuard>
                    )}
                />
            </Routes>
        </Router>
    );
}

export default AppRouter;