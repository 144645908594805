import { useMediaQuery } from "@mui/material";
import React from "react";
import MobileNote from "../../pages/Rental Agreement/MobileNote";

export default function MobileGuard(props: { children: React.ReactNode }) {
    const isMobilePortrait = useMediaQuery('(max-width:600px) and (orientation:portrait)');
    const isMobileLandscape = useMediaQuery('(max-width:900px) and (orientation:landscape)');
    const isMobile = isMobilePortrait || isMobileLandscape;

    if (isMobile) {
        return <MobileNote />
    }

    return props.children
}