import { getAnalytics, setAnalyticsCollectionEnabled } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
import { isDev } from './utils/GlobalUtils'

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const analytics = getAnalytics(app)
const storage = getStorage(app)

if (isDev()) {
	const functions = getFunctions(app)

	connectFirestoreEmulator(db, 'localhost', 8079)
	connectAuthEmulator(auth, 'http://localhost:9098')
	connectFunctionsEmulator(functions, 'localhost', 5001)
	connectStorageEmulator(storage, 'localhost', 9198)
	setAnalyticsCollectionEnabled(analytics, false)
} else {
	setAnalyticsCollectionEnabled(analytics, true)
}

export {
	analytics,
	auth,
	db,
	firebaseConfig,
	storage
}
