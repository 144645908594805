import { Box, Typography } from "@mui/material";
import React from "react";
import Button from "../../components/Button";
import Loading from "../../components/Loading";

type ContentProps = {
    title: string;
    paragraphs: string[];
    loading?: boolean;
    children?: React.ReactNode;
    doneInfo: {
        title: string
        enabled: boolean
        hidden?: boolean
        onClick: () => void
    }
}

export default function Content(props: ContentProps) {
    const { title, paragraphs, children, doneInfo, loading } = props;

    if (loading) {
        return (
            <Box sx={{ flex: '1 1 66%' }}>
                <Loading />
            </Box >
        )
    }

    return (
        <Box
            sx={{
                padding: '20vh 10vw 25vh 10vw',
                flex: '1 1 66%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                boxSizing: 'border-box',
                overflowY: 'scroll',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: {
                        lg: 'row',
                        xs: 'column'
                    }
                }}
            >
                <Box
                    sx={{
                        paddingRight: { lg: children ? '5vw' : 0 },
                        marginBottom: { xs: '5vh' }
                    }}
                >
                    <Typography variant="h4">{title}</Typography>
                    {paragraphs.map((paragraph, index) => (
                        <Typography key={index} variant="body1" sx={{ marginTop: '2vh' }}>
                            {paragraph}
                        </Typography>
                    ))}
                </Box>

                {children}
            </Box>

            {!doneInfo.hidden && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        title={doneInfo.title}
                        disabled={!doneInfo.enabled}
                        onClick={doneInfo.onClick}
                        sx={{ width: '150px', marginRight: 0 }}
                    />
                </div>
            )}
        </Box>
    )
}