import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import AppRouter from './components/navigation/Router';

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	tracesSampleRate: 1.0,
	tracePropagationTargets: ["localhost", /^https:\/\/pv-rental\.uc\.r\.appspot\.com/],
});

export default function App() {
	const theme = createTheme({
		typography: {
			fontFamily: 'Montserrat, sans-serif',
			h3: {
				fontWeight: 'bold',
			},
			h4: {
				fontWeight: 'bold',
			},
			h5: {
				fontWeight: 'bold',
			}
		},
	});

	return (
		<ThemeProvider theme={theme}>
			<AppRouter />
		</ThemeProvider>
	);
}

const container = document.getElementById("app");
if (container) {
	const root = createRoot(container);
	root.render(<App />);
} else {
	console.error('Failed to find the root element');
}