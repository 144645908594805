import * as Sentry from '@sentry/react'

function isDev() {
    return process.env.REACT_APP_ENV === 'dev'
}

function isPreview() {
    return process.env.REACT_APP_ENV === 'preview'
}

async function fetchRequest(endpoint: string, body = {}) {
    let reqBody: any = { ...body }
    if (isPreview()) {
        reqBody.isTestAccount = true
    }

    const urlBase = process.env.REACT_APP_SERVER_URL
    const res = await fetch(`${urlBase}/${endpoint}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(reqBody)
    })

    const json = await res.json()
    if (json.error) {
        window.alert(`Error: ${json.error}`)
        return
    }

    return json
}

function errorAlert(e: any) {
    const message = e?.message ?? 'unknown'
    window.alert(`Error: ${message}\n\nPlease contact info@pv-rental.com for assistance`)
}

function logError(e: any) {
    Sentry.captureException(e)
    errorAlert(e)
}

export {
    errorAlert,
    fetchRequest,
    isDev,
    isPreview,
    logError
}

