import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
    wrapper: {
        width: '80%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },

    search: {
        width: 150,
        height: 35,
        color: 'white',
        backgroundColor: '#fa4903',
        border: 'none',
        borderRadius: 5,
        fontWeight: 'bold',
        fontSize: 14,
        '&:hover': {
            backgroundColor: 'white',
            color: '#fa4903',
            cursor: 'pointer',
            border: 'solid 2px #fa4903'
        },
        '&:disabled': {
            backgroundColor: 'gray',
            border: 'none',
            color: 'white',
            cursor: 'default'
        }
    },

    cancel: {
        width: 150,
        height: 35,
        marginLeft: 15,
        color: 'gray',
        backgroundColor: 'white',
        border: 'solid 2px gray',
        borderRadius: 5,
        fontWeight: 'bold',
        fontSize: 14,
        '&:hover': {
            backgroundColor: 'white',
            color: '#fa4903',
            cursor: 'pointer',
            border: 'solid 2px #fa4903'
        },
    },
})

type SearchButtonProps = {
    setShowSearch: (value: boolean) => void
    showCancel: boolean
    handleCancel: () => void
}

export function SearchButton({ setShowSearch, showCancel, handleCancel }: SearchButtonProps) {
    const classes = useStyles()

    const handleDone = async () => {
        setShowSearch(true)
    }

    return (
        <div className={classes.wrapper}>
            <div>
                <button
                    className={classes.search}
                    onClick={handleDone}
                >
                    Search
                </button>
                {showCancel && (
                    <button
                        className={classes.cancel}
                        onClick={handleCancel}
                    >
                        Cancel
                    </button>
                )}
            </div>
        </div>
    )
}