import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { Field } from "./LiveInventory";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const useStyles = makeStyles(() => ({
    root: {
        '& .search-button': {
            backgroundColor: '#fa4903',
            color: 'white',
            '&:hover': {
                backgroundColor: 'white',
                color: '#fa4903'
            },
        },

        '& .MuiBox-root': {
            border: 'none',
            borderRadius: '10px',
        },
    },

    searchInput: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'initial',
            },

            '&:hover fieldset': {
                borderColor: 'gray',
            },

            '&.Mui-focused fieldset': {
                borderColor: '#fa4903',
            },
        },

        '& .MuiInputLabel-outlined': {
            color: 'initial',
            '&.Mui-focused': {
                color: '#fa4903',
            },
        },
    },
}));

type AdvancedSearchProps = {
    handleClose: () => void;
    handleSearch: (data: Record<string, string>) => void;
    fields: Field[];
    searchData: Record<string, string>,
    setSearchData: React.Dispatch<React.SetStateAction<Record<string, string>>>
}

export function AdvancedSearch(props: AdvancedSearchProps) {
    const {
        handleClose,
        handleSearch,
        fields,
        searchData = {},
        setSearchData
    } = props

    const classes = useStyles()
    const [enabled, setEnabled] = useState(false)

    useEffect(() => {
        for (const key in searchData) {
            if (searchData[key] && searchData[key].trim() !== '') {
                setEnabled(true)
                return
            }
        }

        setEnabled(false)
    }, [searchData])

    const statusDropdown = () => {
        return renderRow({
            title: 'Status',
            type: 'Dropdown',
            dropdown_values: ['Available', 'Rented']
        })
    };

    const renderRow = (field: Field) => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {field.title}
                </Typography>
                {renderInput(field)}
            </div>
        )
    }

    const renderInput = (field: Field) => {
        const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
            const value = e.target.value
            setSearchData((prev) => ({ ...prev, [field.title]: value }))
        }

        if (field.type === 'Dropdown') {
            const dropdownValues = field.dropdown_values || [];

            return (
                <FormControl
                    variant="outlined"
                    className={classes.searchInput}
                    style={{ width: '51.5%', marginTop: 16 }}
                >
                    <InputLabel>{field.title}</InputLabel>
                    <Select
                        label={field.title}
                        value={searchData[field.title]}
                        onChange={handleChange}
                    >
                        {dropdownValues.map((value, index) => (
                            <MenuItem key={index} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )
        }

        return (
            <TextField
                type="text"
                className={classes.searchInput}
                label={field.title}
                variant="outlined"
                value={searchData[field.title]}
                onChange={handleChange}
                sx={{ mt: 2 }}
            />
        )
    }

    return (
        <Modal
            className={classes.root}
            onClose={handleClose}
            open={true}
        >
            <Box sx={style}>
                {fields.map(renderRow)}
                {statusDropdown()}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    }}
                >
                    <Button
                        disabled={!enabled}
                        className="search-button"
                        variant="contained"
                        onClick={() => handleSearch(searchData)}
                        sx={{
                            mt: 6
                        }}
                    >
                        Search
                    </Button>
                </div>
            </Box>
        </Modal>
    );
}