import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { makeStyles } from "@mui/styles";
import Loading from '../../components/Loading';

type AppCompleteProps = {
    message?: string;
    loading?: boolean;
}

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '30vh',
        height: '100vh',
        width: '100vw'
    },

    message: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'gray'
    },

    loading: {
        height: 50
    }
}))

export function AppComplete(props: AppCompleteProps) {
    const {
        loading = false,
        message = 'You may now return to the app'
    } = props;

    const classes = useStyles();

    if (loading) {
        return <Loading message={message} />
    }

    return (
        <div className={classes.root}>
            <CloudDoneIcon style={{ fontSize: 100, color: '#fa4903' }} />
            <h1 className={classes.message}>
                {message}
            </h1>
        </div>
    );
}