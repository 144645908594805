
import {
    DocumentData,
    QueryConstraint,
    QueryDocumentSnapshot,
    collection,
    doc,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    startAfter,
    where
} from 'firebase/firestore';

import { makeStyles } from '@mui/styles';
import { cloneDeep, memoize } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Dropdown } from '../../components/Dropdown';
import EmptyPage from '../../components/EmptyPage';
import Loading from '../../components/Loading';
import { Column, PaginatedTable } from '../../components/PaginatedTable';
import { db } from '../../firebaseConfig';
import { AdvancedSearch } from './AdvancedSearch';
import { SearchButton } from './SearchButton';

export type Field = {
    title: string,
    type: string,
    dropdown_values?: string[]
}

type Item = {
    status: 'Missing' | 'Available' | 'Rented',
    [key: string]: string
}

type ProductPreview = {
    id: string,
    name: string,
    numItems?: number,
}

export const defaultPoleVaultFields: Field[] = [
    {
        title: 'Brand',
        type: 'Dropdown',
        dropdown_values: [
            'Essx',
            'UCS',
            'Pacer',
            'Pacer Carbon',
            'Skypole',
            'Mystic',
            'Rocket',
            'Altius',
            'Fiber Sport',
            'Dynasty',
            'Nordic'
        ]
    },
    {
        title: 'Length Ft',
        type: 'Number'
    },
    {
        title: 'Length In',
        type: 'Number'
    },
    {
        title: 'Weight',
        type: 'Number'
    },
    {
        title: 'Flex',
        type: 'Number'
    },
]

export const restrictedFields = [
    'id',
    'club_id',
    'return_timestamp',
    'rental_timestamp',
    'product_id',
    'sub_id',
    'admin_fields'
]

const useStyles = makeStyles({
    wrapper: {
        width: '100vw',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: '10vh',
        boxSizing: 'border-box',
        overflow: 'scroll',
        paddingBottom: '10vh',
    },

    header: {
        boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px'
    },

    searchWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '80%',
        marginBottom: 20
    }
})

export default function LiveInventory() {
    const numRows = 10
    const classes = useStyles()
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState<Item[]>([])
    const [searchRows, setSearchRows] = useState<Item[]>([])
    const [loading, setLoading] = useState(true)
    const [totalRows, setTotalRows] = useState(0)
    const [isSearching, setIsSearching] = useState(false)
    const [fields, setFields] = useState<Field[]>([])
    const [columns, setColumns] = useState<Column[]>([])
    const [showSearch, setShowSearch] = useState(false)
    const [searchData, setSearchData] = useState({})
    const [productID, setProductID] = useState<string | null>(null)
    const [products, setProducts] = useState<ProductPreview[]>([])

    let lastDoc = useRef<QueryDocumentSnapshot<unknown, DocumentData> | null>()
    let allFetched = useRef(false)

    const clubID = new URLSearchParams(window.location.search).get('club')
    const isDefaultPV = fields.every(field => defaultPoleVaultFields.includes(field))
    const noSearchResultsMessage = 'Looks like this search did not have any results...please try again with a different query.'
    const noClubMessage = 'Looks like this club does not have anything in their inventory yet...'

    const getDataHandler = async (
        prodID: string,
        fields: Field[],
        searchData?: Record<string, string>,
        isInit?: boolean
    ) => {
        let queryParams: QueryConstraint[] = [];

        if (prodID === 'pv_poles') {
            queryParams = [
                orderBy('Length Ft', 'desc'),
                orderBy('Length In', 'desc'),
                orderBy('Weight', 'desc'),
                limit(numRows)
            ];
        } else {
            queryParams = [
                orderBy(fields[0].title, 'desc'),
                limit(numRows)
            ];
        }

        if (searchData && Object.keys(searchData).length > 0) {
            const res = await getSearchData(
                clubID!,
                prodID,
                searchData
            );

            setSearchRows(res)
            return
        }

        if (allFetched.current) {
            return;
        }

        if (lastDoc.current) {
            queryParams.push(startAfter(lastDoc.current));
        }

        const ref = collection(db, `clubs/${clubID}/products/${prodID}/inventory`)
        const q = query(ref, ...queryParams);

        const documentSnapshots = await getDocs(q)
        const data = documentSnapshots.docs.map(document => document.data() as Item)

        if (!isInit) {
            setRows(filterRows(rows, data))
        } else {
            setRows(filterRows([], data))
        }

        lastDoc.current = documentSnapshots.docs[documentSnapshots.docs.length - 1]
        allFetched.current = data.length < numRows
    }

    const getData = memoize(getDataHandler)

    const getTotalHandler = async () => {
        const ref = doc(db, `clubs/${clubID}`)
        const snap = await getDoc(ref)
        if (!snap.exists()) return

        const { currNumItems } = snap.data()
        setTotalRows(currNumItems)
    }

    const getTotal = memoize(getTotalHandler)

    useEffect(() => {
        if (!productID) {
            return
        }

        const init = async (prodID: string, fields: Field[]) => {
            allFetched.current = false
            lastDoc.current = null

            setRows([])
            setSearchRows([])
            setPage(0)
            setIsSearching(false)
            setSearchData({})

            await getTotal()
            await getData(prodID, fields, undefined, true)
        }

        const getFields = async () => {
            if (!clubID || !productID) {
                return
            }

            const { fields, columns } = await lookupFields(clubID, productID)

            setFields(fields)
            setColumns(columns)

            await init(productID, fields)
        }

        setLoading(true)
        getFields()
            .then(() => setLoading(false))
    }, [productID])

    useEffect(() => {
        setLoading(true)
        getProducts(clubID!)
            .then((res) => {
                setProducts(res.products)
                setProductID(res.productID)
                setLoading(false)
            })
    }, [])

    const handleChangePage = (newPage: number) => {
        if (!productID) {
            return
        }

        getData(productID, fields, searchData)
            .then(() => setPage(newPage))
    };

    const handleSearch = async () => {
        if (!productID) {
            return
        }

        setLoading(true)
        setShowSearch(false)
        setIsSearching(true)
        setSearchRows([])
        setPage(0)

        await getData(productID, fields, searchData)
        setLoading(false)
    }

    const getRowValue = (row: Item, column: Column) => {
        let value = row[column.label === 'Status' ? 'status' : column.label];
        if (isDefaultPV && column.label === 'Length') {
            value = `${row['Length Ft']}' ${row['Length In']}"`
        }

        return value
    }

    const getRowStyle = (row: Item) => {
        return {
            backgroundColor: row.status === 'Rented' ? 'lightgray' : 'none'
        }
    }

    if (loading) {
        return <Loading />
    }

    if (!rows || rows.length === 0) {
        return <EmptyPage message={noClubMessage} />
    }

    return (
        <div className={classes.wrapper}>
            {showSearch && (
                <AdvancedSearch
                    fields={fields}
                    handleClose={() => setShowSearch(false)}
                    handleSearch={handleSearch}
                    searchData={searchData}
                    setSearchData={setSearchData}
                />
            )}

            <div
                className={classes.searchWrapper}
                style={{
                    justifyContent: products.length > 1 ? 'space-between' : 'flex-end'
                }}
            >
                {products.length > 1 && (
                    <Dropdown
                        label='Product'
                        items={products.map(product => ({ name: product.name, value: product.id }))}
                        selectedValue={productID}
                        onChange={setProductID}
                    />
                )}

                <SearchButton
                    setShowSearch={setShowSearch}
                    showCancel={isSearching}
                    handleCancel={() => {
                        setSearchData({})
                        setIsSearching(false)
                        setSearchRows([])
                        setPage(0)
                    }}
                />
            </div>


            <PaginatedTable
                columns={columns}
                rows={isSearching ? searchRows : rows}
                noResultsMessage={noSearchResultsMessage}
                page={page}
                numRows={numRows}
                totalRows={isSearching ? searchRows.length : totalRows}
                onPageChange={handleChangePage}
                getRowValue={getRowValue}
                getRowStyle={getRowStyle}
            />
        </div >
    );
}


const filterRows = (currRows: Item[], data: Item[]) => {
    const filteredData = data.filter((item: Item) => item.status !== 'Missing')
    const newRows = filteredData.map((item: Item) => {
        let tmp = cloneDeep(item)
        for (const restrictedField of restrictedFields) {
            delete tmp[restrictedField]
        }

        return tmp
    })

    const res = [...currRows, ...newRows]
    return res
}

const getSearchDataHandler = async (
    clubID: string,
    productID: string,
    searchData: Record<string, string>
) => {
    console.log('searchData', searchData)
    let queryParams: QueryConstraint[] = []

    for (const key of Object.keys(searchData)) {
        const formattedKey = key === 'Status' ? 'status' : key
        queryParams.push(where(formattedKey, '==', searchData[key]))
    }

    const ref = collection(db, `clubs/${clubID}/products/${productID}/inventory`)
    const q = query(ref, ...queryParams)
    const documentSnapshots = await getDocs(q)
    const data = documentSnapshots.docs.map(document => document.data() as Item)

    return filterRows([], data)
}

const getSearchDataResolver = (
    clubID: string,
    productID: string,
    searchData: Record<string, string>
) => JSON.stringify([searchData, clubID, productID])

const getSearchData = memoize(getSearchDataHandler, getSearchDataResolver)

const getProductsHandler = async (clubID: string) => {
    const ref = doc(db, `clubs/${clubID}`)
    const snap = await getDoc(ref)
    const data = snap.data()

    if (!data || !data?.products) {
        return {
            products: [],
            productID: 'pv_poles'
        }
    } else {
        const parsedProducts = Object.values(data.products) as ProductPreview[]
        return {
            products: parsedProducts,
            productID: parsedProducts[0].id
        }
    }
}

const getProducts = memoize(getProductsHandler)

const lookupFieldsHandler = async (clubID: string, productID: string) => {
    const ref = doc(db, `clubs/${clubID}/products/${productID}`)
    const snap = await getDoc(ref)
    const data = snap.data()

    if (!data || !data?.fields) {
        const columns = [
            { id: 'length', label: 'Length', minWidth: 100 },
            { id: 'weight', label: 'Weight', minWidth: 100 },
            { id: 'flex', label: 'Flex', minWidth: 100 },
            { id: 'brand', label: 'Brand', minWidth: 100 },
            { id: 'status', label: 'Status', minWidth: 100 }
        ]

        return {
            fields: defaultPoleVaultFields,
            columns
        }
    }

    const { fields } = data
    let columns = fields.map((field: Field) => {
        const title = field.title
        return { id: title.toLowerCase(), label: title, minWidth: 100 }
    })

    columns.push({ id: 'status', label: 'Status', minWidth: 100 })
    return {
        fields,
        columns
    }
}

const lookupFieldsResolver = (clubID: string, productID: string) => JSON.stringify([clubID, productID])
const lookupFields = memoize(lookupFieldsHandler, lookupFieldsResolver)