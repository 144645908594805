import OnboardingContent from "../../components/OnboardingSteps/OnboardingContent";

export default function AgreementComplete() {
    const paragraphs = [
        'Your rental agreement is now complete. You may replace or edit the agreement at any time by navigating to the drawer menu and opening the "Rental Agreement" screen.',
    ]

    return (
        <OnboardingContent
            title='Complete'
            paragraphs={paragraphs}
        />
    )
}