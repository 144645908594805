import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AccordionList from "../../components/AccordionList";
import { BulletedList } from "../../components/BulletedList";
import Loading from "../../components/Loading";
import LinkButtons from "./LinkButtons";
import { ContentfulEntryType, getPageData } from './userManualHelpers';

export type FeaturePageProps = {
    id: string;
    usePreview?: boolean;
}

type OverrideType = {
    insertIndex: number;
    componentType: string;
    componentProps: any;
}

type PageDataType = {
    title: string;
    description: string;
    faqs?: { question: string, answer: string }[];
    thingsToNote?: string[];
    steps?: string[];
    overrides?: OverrideType[];
}

export default function FeaturePage(props: FeaturePageProps) {
    const { id, usePreview = false } = props;
    const [loading, setLoading] = useState(true)
    const [pageData, setPageData] = useState<PageDataType | null>(null)

    useEffect(() => {
        getPageData(id)
            .then((res: ContentfulEntryType) => {
                const fields = res.fields as PageDataType
                setPageData(fields)
            })
            .finally(() => setLoading(false))
    }, [id, usePreview])

    if (loading) {
        return <Loading />
    }

    if (!pageData) {
        return <p>Page not found</p>
    }

    const elements = getPageElements(pageData)

    return (
        <Box
            sx={{
                paddingTop: { lg: '15vh', md: '10vh', sm: '8vh' },
                paddingBottom: { lg: '15vh', md: '10vh', sm: '8vh' },
                paddingLeft: { lg: '15vw', md: '10vw', sm: '8vw' },
                paddingRight: { lg: '15vw', md: '10vw', sm: '8vw' },
            }}
        >
            {elements.map(element => element)}
        </Box>
    );
}

const getPageElements = (pageData: PageDataType): React.ReactNode[] => {
    const faqs = pageData?.faqs?.map(faq => ({ title: faq.question, content: faq.answer }))
    const thingsToNote = pageData?.thingsToNote
    const steps = pageData?.steps
    const overrides = pageData?.overrides || []
    const descriptionParagraphs = pageData.description.split('\n')

    let elements = [(
        <Typography variant="h4" sx={{ marginBottom: '1.5rem' }} key={0}>
            {pageData.title}
        </Typography>
    )]

    for (const paragraph of descriptionParagraphs) {
        elements.push(
            <Typography
                variant="body1"
                sx={{ marginBottom: '1rem' }}
            >
                {paragraph}
            </Typography>
        )
    }

    if (steps) {
        elements.push(
            <BulletedList title="Steps" items={steps} isNumbered />
        )
    }

    if (thingsToNote) {
        elements.push(
            <BulletedList title="Things to Note" items={thingsToNote} />
        )
    }

    if (faqs) {
        elements.push(
            <AccordionList title="FAQs" accordions={faqs} />
        )
    }

    const getComponentFromType = (type: string, props: any) => {
        switch (type) {
            case 'list':
                return <BulletedList {...props} />
            case 'accordion':
                return <AccordionList {...props} />
            case 'title':
                return (
                    <Typography variant="h4" sx={{ marginBottom: '1.5rem' }}>
                        {props.title}
                    </Typography>
                )
            case 'description':
                return (
                    <Typography variant="body1">
                        {props.description}
                    </Typography>
                )
            case 'linkButtons':
                return <LinkButtons {...props} />
            default:
                console.error(`Component type ${type} not found`)
                return null
        }
    }

    for (const override of overrides) {
        const element = getComponentFromType(override.componentType, override.componentProps)

        if (element) {
            elements.splice(override.insertIndex, 0, element)
        }
    }

    return elements
}