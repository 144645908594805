import { Box, Typography } from "@mui/material";
import React from "react";
import Button from "../../components/Button";
import Loading from "../../components/Loading";

type DoneButton = {
    title: string
    enabled: boolean
    onClick: () => void
}

type ContentProps = {
    title: string;
    paragraphs: string[];
    loading?: boolean;
    children?: React.ReactNode;
    doneButtons?: DoneButton[];
}

export default function OnboardingContent(props: ContentProps) {
    const { title, paragraphs, children, doneButtons, loading } = props;

    if (loading) {
        return (
            <Box sx={{ flex: '1 1 66%' }}>
                <Loading />
            </Box >
        )
    }

    return (
        <Box
            sx={{
                padding: '20vh 10vw 25vh 10vw',
                flex: '1 1 66%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                boxSizing: 'border-box',
                overflowY: 'scroll',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: {
                        lg: 'row',
                        xs: 'column'
                    }
                }}
            >
                <Box
                    sx={{
                        paddingRight: { lg: children ? '5vw' : 0 },
                        marginBottom: { xs: '5vh' }
                    }}
                >
                    <Typography variant="h4">{title}</Typography>
                    {paragraphs.map((paragraph, index) => (
                        <Typography key={index} variant="body1" sx={{ marginTop: '2vh' }}>
                            {paragraph}
                        </Typography>
                    ))}
                </Box>

                {children}
            </Box>

            {doneButtons && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                    }}
                >
                    {doneButtons.map((doneInfo, index) => (
                        <Button
                            key={index}
                            title={doneInfo.title}
                            disabled={!doneInfo.enabled}
                            onClick={doneInfo.onClick}
                            sx={{
                                width: '150px',
                                marginRight: index === doneButtons.length - 1 ? 0 : '20px'
                            }}
                        />
                    ))}
                </div>
            )}
        </Box>
    )
}