import { doc, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useRef, useState } from 'react';
import OnboardingContent from "../../components/OnboardingSteps/OnboardingContent";
import { db, storage } from '../../firebaseConfig';
import { logError } from '../../utils/GlobalUtils';

type UploadAgreementProps = {
    clubID: string;
    handleNext: () => void;
}

export default function UploadAgreement(props: UploadAgreementProps) {
    const { clubID, handleNext } = props;
    const [file, setFile] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);

    const paragraphs = [
        'Your consent has been received. The next step in the process is to upload a PDF copy of your rental agreement. Ensure that the agreement is up to date and upload it by pressing the button below.',
        'Please Note: PVR takes no responsibility for the contents or consequences of your rental agreement.'
    ];

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const handleUpload = async (file: File) => {
        try {
            setLoading(true)

            const storageRef = ref(storage, `clubs/${clubID}/bold_sign.pdf`)
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on('state_changed',
                () => { },
                (error) => {
                    logError(error);
                    setLoading(false);
                },
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    const clubRef = doc(db, `clubs/${clubID}`)
                    await setDoc(clubRef, {
                        boldSign: {
                            storageURL: downloadURL
                        }
                    }, { merge: true });

                    handleNext()
                }
            );
        } catch (error) {
            logError(error)
        } finally {
            setLoading(false)
        }
    }

    const doneButtons = !file ? [
        {
            title: 'Upload',
            enabled: true,
            onClick: handleButtonClick
        }
    ] : [
        {
            title: 'Replace',
            enabled: true,
            onClick: handleButtonClick
        },
        {
            title: 'Next',
            enabled: true,
            onClick: () => handleUpload(file)
        }
    ];

    return (
        <OnboardingContent
            title='Upload Agreement'
            paragraphs={paragraphs}
            doneButtons={doneButtons}
            loading={loading}
        >
            <input
                type="file"
                ref={fileInputRef}
                accept="application/pdf"
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </OnboardingContent>
    );
}