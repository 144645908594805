import OnboardingContent from "../../components/OnboardingSteps/OnboardingContent";

export default function MobileNote() {
    const paragraphs = [
        'In order to effectively construct your rental agreement, please use a desktop or laptop computer.',
    ]

    return (
        <OnboardingContent
            title='Desktop Required'
            paragraphs={paragraphs}
        />
    )
}