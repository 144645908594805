import { useState } from "react";
import OnboardingContent from "../../components/OnboardingSteps/OnboardingContent";
import { fetchRequest, logError } from "../../utils/GlobalUtils";

type FormatAgreementProps = {
    clubID: string
    handleBack: () => void
}

export default function FormatAgreement(props: FormatAgreementProps) {
    const { clubID, handleBack } = props;
    const [loading, setLoading] = useState(false)

    const paragraphs = [
        'The final step in creating your rental agreement is to set the input and signature fields within the document. Pressing the button below will open the rental agreement editor, allowing you to drag and drop fields into the document.',
    ]

    const handleFormat = async () => {
        try {
            setLoading(true)

            const { url } = await fetchRequest('createBoldSignTemplate', {
                clubID
            })

            if (!url) {
                throw new Error('Failed to create agreement')
            }

            window.open(url, '_self')
        } catch (error) {
            logError(error)
            setLoading(false)
        }
    }

    const doneButtons = [
        {
            title: 'Back',
            enabled: true,
            onClick: handleBack
        },
        {
            title: 'Format',
            enabled: true,
            onClick: handleFormat
        }
    ]

    return (
        <OnboardingContent
            title='Format Agreement'
            paragraphs={paragraphs}
            doneButtons={doneButtons}
            loading={loading}
        />
    )
}