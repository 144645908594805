import { useState } from "react";
import OnboardingContent from "../../components/OnboardingSteps/OnboardingContent";
import { fetchRequest, logError } from "../../utils/GlobalUtils";

type EditAgreementProps = {
    clubID: string
    handleReplace: () => void
}

export default function EditAgreement(props: EditAgreementProps) {
    const { clubID, handleReplace } = props;
    const [loading, setLoading] = useState(false)

    const paragraphs = [
        'Your rental agreement is active. To update the form fields, press the edit button. To upload a new PDF, press the replace button.',
    ]

    const handleEdit = async () => {
        try {
            setLoading(true)
            const { editUrl } = await fetchRequest('editBoldSignTemplate', { clubID })

            window.open(editUrl, '_self')
        } catch (error) {
            logError(error)
        }
    }

    const doneButtons = [
        {
            title: 'Replace',
            enabled: true,
            onClick: handleReplace
        },
        {
            title: 'Edit',
            enabled: true,
            onClick: handleEdit
        }
    ]

    return (
        <OnboardingContent
            title='Edit Agreement'
            paragraphs={paragraphs}
            doneButtons={doneButtons}
            loading={loading}
        />
    )
}