import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import EmptyPage from "../../components/EmptyPage";
import Loading from "../../components/Loading";
import OnboardingSteps, { OnboardingStep } from "../../components/OnboardingSteps/OnboardingSteps";
import { auth, db } from "../../firebaseConfig";
import { logError } from "../../utils/GlobalUtils";
import AgreementComplete from "./AgreementComplete";
import AgreementConsent from "./AgreementConsent";
import EditAgreement from "./EditAgreement";
import FormatAgreement from "./FormatAgreement";
import UploadAgreement from "./UploadAgreement";

enum Steps {
    CONSENT = 'Consent',
    UPLOAD = 'Upload',
    FORMAT = 'Format',
    EDIT = 'Edit Agreement',
    COMPLETE = 'Complete'
}

export function RentalAgreement() {
    const [activeStep, setActiveStep] = useState<Steps>()
    const [clubID, setClubID] = useState<string>()
    const [loading, setLoading] = useState(true)

    const searchParams = new URLSearchParams(window.location.search)
    const complete = !!searchParams.get('complete')

    const onboardingSteps: OnboardingStep[] = [
        {
            title: Steps.CONSENT,
            component: <AgreementConsent />
        },
        {
            title: Steps.UPLOAD,
            component: (
                <UploadAgreement
                    clubID={clubID!}
                    handleNext={() => setActiveStep(Steps.FORMAT)}
                />
            )
        },
        {
            title: Steps.FORMAT,
            component: (
                <FormatAgreement
                    clubID={clubID!}
                    handleBack={() => setActiveStep(Steps.UPLOAD)}
                />
            )
        },
        {
            title: Steps.COMPLETE,
            component: <AgreementComplete />
        }
    ]

    const editingSteps: OnboardingStep[] = [
        {
            title: Steps.EDIT,
            component: (
                <EditAgreement
                    clubID={clubID!}
                    handleReplace={() => setActiveStep(Steps.UPLOAD)}
                />
            )
        }
    ]

    const steps = activeStep === Steps.EDIT ? editingSteps : onboardingSteps
    const step = steps.find(step => step.title === activeStep) || steps[0]

    useEffect(() => {
        async function init() {
            if (complete) {
                setActiveStep(Steps.COMPLETE)
                return
            }

            const uid = auth.currentUser?.uid
            const userRef = doc(db, `users/${uid}`)
            const userSnap = await getDoc(userRef)
            const userData = userSnap.data()

            const club = userData?.club
            if (!club) {
                return
            }

            setClubID(club)

            const clubRef = doc(db, `clubs/${club}`)
            const clubSnap = await getDoc(clubRef)
            const clubData = clubSnap.data()

            const hasConsent = !!clubData?.boldSign?.senderEmail
            if (!hasConsent) {
                setActiveStep(Steps.CONSENT)
                return
            }

            const hasTemplateId = clubData?.boldSign?.templateId
            if (hasTemplateId) {
                setActiveStep(Steps.EDIT)
                return
            }

            const hasStorgeUrl = clubData?.boldSign?.storageURL
            if (!hasStorgeUrl) {
                setActiveStep(Steps.UPLOAD)
                return
            }

            setActiveStep(Steps.FORMAT)
        }

        init()
            .catch(error => logError(error))
            .finally(() => setLoading(false))
    }, [])

    if (loading) {
        return <Loading />
    }

    if (!clubID && !complete) {
        return (
            <EmptyPage
                message="You do not appear to be associated with a club. This feature is only available for club owners."
            />
        )
    }

    return (
        <OnboardingSteps
            title="Rental Agreement"
            steps={steps}
            activeStep={step}
        />
    )
}