import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { ProgressPane } from "./ProgressPane";

export type OnboardingStep = {
    title: string
    component: React.ReactNode
}

type OnboardingStepsProps = {
    title: string
    steps: OnboardingStep[]
    activeStep: OnboardingStep
}

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: '100vh',
        width: '100vw',
    }
}))

export default function OnboardingSteps(props: OnboardingStepsProps) {
    const classes = useStyles()
    const { title, steps, activeStep } = props
    const stepNames = steps.map((step: OnboardingStep) => step.title)

    return (
        <Box className={classes.container}>
            <ProgressPane
                title={title}
                steps={stepNames}
                activeStep={stepNames.indexOf(activeStep.title)}
            />

            {activeStep.component}
        </Box>
    )
}